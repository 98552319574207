<template>
  <div style="height: 100vh; overflow: hidden">
    <div style="height: 100%" class="px-2">
      <v-row class="fill-height">
        <v-col cols="12" sm="12" md="5">
          <v-row class="fill-height" style="display: flex; flex-wrap: nowrap; flex-direction: column;">
            <v-col cols="12" style="flex: 0">
              <v-toolbar elevation="1" class="non-printable" style="position: relative;">
                <v-toolbar-title>Користувачі</v-toolbar-title>
                <v-spacer/>
                <v-menu bottom left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on" class="pr-2">
                      <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>

                  <v-list nav>
                    <v-list-item @click="getFetch('all')">
                      <v-list-item-title>
                        Усі користувачі
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="getFetch('organization_user')">
                      <v-list-item-title>
                        Користувачі підприємства
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="getFetch('cabinet_user')">
                      <v-list-item-title>
                        Користувачі персонального кабінету
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
                <v-btn
                    v-if="!simple_user"
                    class="grey lighten-3 mr-2"
                    icon
                    outlined
                    @click="addNewUser"
                >
                  <v-icon color="grey darken-1">mdi-plus</v-icon>
                </v-btn>
              </v-toolbar>
            </v-col>
            <v-col cols="12" style="flex: 1;">
              <v-list
                  nav
                  dense
                  class="pa-0 grey lighten-5"
                  two-line
                  style="height: calc(100vh - 90px); overflow: auto"
                  v-if="items.length > 0">
                  <v-card  v-for="(item, idx) in items" :key="idx" class="mb-2"
                           @click.stop="fillSelectedObject(idx)"
                           :style="selectedUser === idx ? 'background-color: #e8e8e8' : ''"
                  >
                    <v-list-item>
                      <v-list-item-avatar
                          :color="item.is_active ? 'grey' : 'error lighten-1'"
                      >
                        <v-icon dark size="20" color="white">{{ item.is_active ? 'mdi-account-check-outline' : 'mdi-account-lock-outline' }}</v-icon>
                      </v-list-item-avatar>

                      <v-list-item-content>
                        <v-list-item-title class="subtitle-2 mb-2">{{ item.name }}</v-list-item-title>
                        <v-list-item-subtitle class="d-flex flex-wrap">
                          <v-chip label small
                                  v-if="item.position"
                                  :color="selectedUser === idx ? 'grey lighten-1' : 'grey lighten-3'"
                                  class="mr-2 font-weight-regular mb-1">{{ item.position }}
                          </v-chip>
                          <v-chip label small
                                  :color="selectedUser === idx ? 'grey lighten-1' : 'grey lighten-3'"
                                  class="mr-2 font-weight-regular"
                                  v-for="(role, idx_r) in item.roles.filter(i => i.active)" :key="`role-${idx_r}`"
                          >{{ role.name }}
                          </v-chip>
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider></v-divider>
                    <v-card-actions>
                      <div class="d-flex">
                        <v-icon size="17" class="mr-2">mdi-calendar</v-icon>
                        <span style="font-size: 0.72rem">{{ item.create_date | formatDate }}</span>
                      </div>
                      <v-spacer></v-spacer>
                      <div class="d-flex" v-if="!item.accepted">
                        <v-icon size="17" class="mr-2">mdi-lock</v-icon>
                        <span style="font-size: 0.8rem">
                          Не активовано
                        </span>
                      </div>
                    </v-card-actions>
                  </v-card>
              </v-list>
              <v-card v-else class="fill-height transparent pb-7 pr-1" tile elevation="0">
                <div class="d-flex fill-height align-center justify-center">
                  <div class="wrapper align-center text-center">
                    <v-icon size="200" color="success" style="opacity: .24">mdi-clock</v-icon>
                    <div class="title grey--text text--darken-2 px-4">Дані для відображення відсутні. <br> Спершу створіть користувача</div>
                  </div>
                </div>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" sm="12" md="7">
          <v-row class="fill-height" style="display: flex; flex-wrap: nowrap; flex-direction: column">
            <v-col cols="12" style="flex: 0">
              <v-toolbar elevation="1" class="non-printable" style="position: relative;">
                <v-toolbar-title>Дані користувача</v-toolbar-title>
              </v-toolbar>
            </v-col>
            <v-col cols="12" style="flex: 1">
              <v-card v-if="user.new || user.id" :disabled="simple_user && profile.id !== user.id">
                <v-card-text>
                  <v-form
                      v-model="formValid" ref="form"
                      autocomplite="off"
                  >
                    <v-row>
                      <v-col cols="12" class="d-flex pb-0">
                        <v-text-field type="text"
                                      filled
                                      label="П.І.Б"
                                      v-model="user.name"
                                      :rules="[rules.required]"
                                      class="mr-2"
                                      color="grey"
                        />
                        <v-checkbox
                            hide-details
                            color="secondary"
                            v-model="user.is_active"
                            :label="user.is_active ? 'Активний' : 'Відключений'"
                        />
                      </v-col>
                      <v-col cols="12" class="pb-0 pt-1">
                        <v-text-field type="text"
                                      filled
                                      label="email"
                                      v-model="user.email"
                                      required
                                      :rules="[rules.required, rules.email]"
                                      color="grey"
                        />
                      </v-col>
                      <v-col cols="12" class="pb-3 pt-1">
                        <AC_Position :value="user.position_id"
                                     @autocompleteChange="positionChange"
                                     color="grey"
                        />
                      </v-col>
                      <v-col cols="12" class="pb-3 pt-1">
                        <Checker :value="user.checker_id"
                                     @autocompleteChange="checkerChange"
                                     color="grey"
                        />
                      </v-col>
                      <v-col cols="12" class="pb-3 pt-1">
                        <AppealReceiverGroup :value="user.appeal_receiver_group_id"
                                             @autocompleteChange="appealReceiverGroupChange"
                                             color="grey"
                        />
                      </v-col>
                      <v-card-text class="px-3 pb-0" v-if="!user.new">
                        <v-checkbox
                            class="mt-0"
                            hide-details
                            color="secondary"
                            v-model="user.change_password"
                            :label="user.change_password ? 'Змінити пароль (зніміть позначку аби не змінювати пароль)' : 'Не змінювати пароль (встановіть позначку аби змінити пароль)'"
                        />
                      </v-card-text>
                      <template v-if="user.change_password || user.new">
                        <v-col cols="12" class="pb-0 pt-3">
                          <v-text-field role="presentation"
                                        filled
                                        label="Новий пароль"
                                        autocomplete="none"
                                        :type="show1 ? 'text' : 'password'"
                                        :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                                        v-model="user.password_new"
                                        required
                                        :rules="[rules.required, rules.min_counter]"
                                        color="grey"
                                        @click:append="show1 = !show1"
                          />
                        </v-col>
                        <v-col cols="12" class="pb-0 pt-1">
                          <v-text-field filled
                                        :type="show2 ? 'text' : 'password'"
                                        :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                                        autocomplete="none"
                                        label="Новий пароль (повторно)"
                                        v-model="user.password_new_again"
                                        required
                                        :rules="[rules.required, rules.min_counter, passwordConfirmationRule]"
                                        color="grey"
                                        @click:append="show2 = !show2"
                          />
                        </v-col>
                      </template>
                      <v-col cols="12">
                        <v-list flat subheader class="mx-2">
                          <v-subheader class="pl-0 subtitle-2">
                            Права користувача
                          </v-subheader>

                          <v-list-item-group
                              multiple
                              active-class=""
                          >
                            <v-row>
                              <v-col cols="6" class="pa-1" v-for="(item, idx) in user.roles" :key="idx">
                                <v-list-item class="grey lighten-5">
                                  <v-list-item-action>
                                    <v-checkbox v-model="item.active"></v-checkbox>
                                  </v-list-item-action>

                                  <v-list-item-content>
                                    <v-list-item-title>{{ item.name }}</v-list-item-title>
                                  </v-list-item-content>
                                </v-list-item>
                              </v-col>
                            </v-row>
                          </v-list-item-group>
                        </v-list>
                      </v-col>
                      <v-col cols="12">
                        <v-btn depressed
                               block
                               color="grey lighten-1"
                               :disabled="!formValid"
                               @click="crudUser"
                        >
                          Зберегти дані користувача
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-card-text>
              </v-card>
              <v-card v-else class="fill-height transparent pb-7" tile elevation="0">
                <div class="d-flex fill-height align-center justify-center">
                  <div class="wrapper align-center text-center">
                    <v-icon size="200" color="success" style="opacity: .24">mdi-clock</v-icon>
                    <div class="title grey--text text--darken-2 px-4">Для відображення даних користувача <br> оберіть його із списку зліва, а за відсутності - створіть</div>
                  </div>
                </div>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {USER_CREATE, USER_GET_ALL_BY_ORG, USER_UPDATE} from "@/store/actions/user";
import userAPI from "@/utils/axios/user"
import {ALERT_SHOW} from "@/store/actions/alert";

export default {
  name: "Users",
  components: {
    AC_Position: () => import("@/components/autocomplite/AC_Position"),
    Checker: () => import("@/components/autocomplite/Checker"),
    AppealReceiverGroup: () => import("@/components/autocomplite/AppealReceiverGroup"),
  },
  computed: {
    ...mapGetters(
        {
          items: 'getUsers',
          simple_user: 'simple_user',
          profile: 'getProfile',
        }
    ),
    passwordConfirmationRule() {
      return this.user.password_new === this.user.password_new_again || 'Паролі не співвпадають'
    }
  },
  data() {
    return {
      show1: false,
      show2: false,
      user: {
        new: false,
        id: null,
        name: '',
        email: '',
        position: null,
        password_new: '',
        password_new_again: '',
        change_password: false,
        checker_id: null,
        is_active: false,
        appeal_receiver_group_id: null,
        roles: [],
        accepted: false
      },
      roles: [],
      selectedUser: null,
      formValid: false,
      rules: {
        required: value => !!value || 'Це поле обов\'язкове',
        min_counter: value => value.length >= 4 || 'Мінімум 4 символи',
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || 'Неправильний email'
        },
      },
    }
  },
  methods: {
    ...mapActions(
        { fetch_users: USER_GET_ALL_BY_ORG }
    ),
    getBasePath() {
      return window.location.origin
    },
    positionChange(payload) {
      this.user.position_id = payload.value || null
    },
    checkerChange(payload) {
      this.user.checker_id = payload.value || null
    },
    appealReceiverGroupChange(payload) {
      this.user.appeal_receiver_group_id = payload.value || null
    },
    addNewUser() {
      this.user.new = true
      this.user.name = ''
      this.user.email = ''
      this.user.position = null
      this.user.change_password = false
      this.user.roles = this.roles.slice()
      this.user.password_new = ''
      this.user.password_new_again = ''
      this.user.id = null
      this.user.is_active = false
      this.user.accepted = false
      this.user.checker_id = null
      this.user.appeal_receiver_group_id = null
    },
    fillSelectedObject(payload) {
      this.selectedUser = payload
      if (payload === undefined) {
        this.user.new = false
        this.user.name = ''
        this.user.email = ''
        this.user.position = ''
        this.user.position_id = null
        this.user.change_password = false
        this.user.roles = []
        this.user.password_new = ''
        this.user.password_new_again = ''
        this.user.id = null
        this.user.is_active = false
        this.user.accepted = false
        this.user.checker_id = null
        this.user.appeal_receiver_group_id = null
      } else {
        const user_obj = this.items[payload]
        this.user.new = false
        this.user.name = user_obj.name
        this.user.email = user_obj.email
        this.user.position = user_obj.position
        this.user.position_id = user_obj.position_id
        this.user.change_password = false
        this.user.roles = user_obj.roles
        this.user.password_new = ''
        this.user.password_new_again = ''
        this.user.id = user_obj.id
        this.user.is_active = user_obj.is_active
        this.user.accepted = user_obj.accepted
        this.user.checker_id = user_obj.checker_id
        this.user.appeal_receiver_group_id = user_obj.appeal_receiver_group_id
      }
    },
    crudUser() {
      let OPERATION = null
      let ALERT_TEXT = null

      const payload = {
        name: this.user.name,
        email: this.user.email,
        position_id: this.user.position_id,
        roles: this.user.roles,
        is_active: this.user.is_active,
        user_url: this.getBasePath(),
        accepted: this.user.accepted,
        checker_id: this.user.checker_id,
        appeal_receiver_group_id: this.user.appeal_receiver_group_id
      }

      if (this.user.new) {
        payload.password = this.user.password_new
        OPERATION = USER_CREATE
        ALERT_TEXT = 'Користувача створено успішно. Лист із ' +
            'посиланням для активації аккаунта надіслано на електронну пошту ' +
            'короистувача'
      } else {
        payload.id = this.user.id
        if (this.user.change_password) {
          payload.password = this.user.password_new
        }
        OPERATION = USER_UPDATE
        ALERT_TEXT = this.user.change_password ? 'Дані користувача було змінено успішно' : 'Оновлення даних користувача було проведено успішно'
      }

      this.$store.dispatch(OPERATION, payload)
          .then(response => {
            if (response) {
              this.user.new = false
              this.user.name = ''
              this.user.email = ''
              this.user.position = null
              this.user.change_password = false
              this.user.roles = this.roles.slice()
              this.user.password_new = ''
              this.user.password_new_again = ''
              this.user.id = null
              this.user.accepted = false
              this.user.checker_id = null
              this.user.appeal_receiver_group_id = null

              this.$store.dispatch(ALERT_SHOW,
                  { text: ALERT_TEXT, color: 'success' })
            }
          })
    },
    getFetch(user_filter=null) {
      const payload = {'role_filter': user_filter}
      this.fetch_users(payload)
    }
  },
  created() {
    const payload = {'role_filter': 'organization_user'}
    this.fetch_users(payload)
      .then(response => {
        if (response) {
          userAPI.get_roles()
            .then(r => r.data)
            .then(data => {
              if ((this.items || []).length === 1) {
                this.fillSelectedObject(0)
              }
              this.roles = data
            })
            .catch(err => {
              const error = err.response.data.detail;
              this.$store.commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
            })
        }
      })
  }
}
</script>

<style scoped lang="scss">
  :deep(.v-text-field__details) {
    margin-bottom: 0 !important;
  }
</style>